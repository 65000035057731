body {
  box-sizing: border-box;
  margin: 0;
  font-family: "Press Start 2P", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
  color: #ffffff;
  background-image: url(/src/test/galaxy.png);
  background-size: cover;
}

code {
  font-family: "Press Start 2P", cursive;
}
.front-banner {
  margin-left: auto;
  margin-right: auto;
  background-repeat:   no-repeat;
  background-position: center center;    
}
h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  margin-bottom: 0px;
  margin-top:0px;
}
h2 {
  padding-top: 0px;
  margin-top: 10px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Change background on mouse-over */

#balance {
  position: absolute;
  top: 0px;
  right: 0;
  padding: 15px;
}
#wallet {
  padding: 15px;
  position: absolute;
  top: 0;
  left: 0;

}
#redeemed-total{
  font-size:25px;
}
#mint-wrapper{
  display: flex;
  height:80px;
  width:200px;
}
#mint {
  width:100%;
  justify-content: center;
  align-items: center;
  background-color: #303f9f;
  color:white;
  font-family: "Press Start 2P", cursive;
  padding-left:20px;
}
main{
  display: flex;
  flex-direction: column-reverse;
}
#root {
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 50px auto;
  margin-top: 0px;
  padding: 30px;
}

#connect-wrapper {
  display: flex;
  height:80px;
  width:300px;
  
}
#connect{
  width:100%;
  justify-content: center;
  align-items: center;
  font-family: "Press Start 2P", cursive;
  
}
#home {
  height:100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
}
nav{
  display:flex;
  justify-content: center;
  width:100vw;
  position: fixed;
}
nav ul{
  list-style: none;
  display: flex;
}
nav a{ 
  text-decoration: none;
  color:white;
  
}
nav a:hover{
  color:orange;
}
nav li{
  display: flex;
  justify-content: center;
  align-items: center;
  padding:10px 40px;
}
svg{
  font-size: 25px;
  padding:10px;
}
.centered{
  margin-bottom: 250px;
  margin-top:100px
}
img{
  width:700px;
  margin-top: 30px;
  margin-bottom: 20px;
}